<template>
  <Browse
    :columns="[
      'customer',
      'date',
      'amount',
      'count_of_items',
      'description',
      'payment_method',
      'amount_tendered',
    ]"
    :formats="{
      date: 'Date',
      amount: 'Money',
      count_of_items: 'Integer6',
      amount_tendered: 'Money',
    }"
    itemsPerPage="10"
    :searchOnStart="true"
    table="sales"
  />
</template>

<script>
import Browse from "@/components/Browse.vue";

export default {
  components: {
    Browse,
  },
};
</script>
