var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Browse',{attrs:{"columns":[
    'customer',
    'date',
    'amount',
    'count_of_items',
    'description',
    'payment_method',
    'amount_tendered' ],"formats":{
    date: 'Date',
    amount: 'Money',
    count_of_items: 'Integer6',
    amount_tendered: 'Money',
  },"itemsPerPage":"10","searchOnStart":true,"table":"sales"}})}
var staticRenderFns = []

export { render, staticRenderFns }